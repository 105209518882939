import React, {Component} from 'react';
import Header from './Header';
import Footer from './Footer';
import Menu from './Menu';
import {Redirect} from 'react-router-dom';
import { PostData } from './PostData';
import LatestNews from './LatestNews.js';
import TopNews from './TopNews.js';
import BulletinSlider from './BulletinSlider.js';
import SubNewsSlider from './SubNewsSlider.js';

class HomePage extends Component {
	constructor() {
		super();
	    this.state= {
			data:[],
			bulletindata: [],
	    	response: "latest_news",
			latestNews: [],
			topNews: [],
			sliderNews: [],
			bulletinNews: [],
			catMenu: [],
		}
		
		this.fetchHomePageData = this.fetchHomePageData.bind(this);
	}

	componentDidMount() {
		this.fetchHomePageData()
	}

	fetchHomePageData() {
		let data = { language:'E', type:"", limit:20};
		PostData('LatestNews', data).then((result) => {
			let responseJson = result;
			// console.log(responseJson);
			this.setState({latestNews: responseJson});
		});

		let tndata = { language:'E', type:"topnews"};
		PostData('TopNews', tndata).then((result) => {
			let responseJson = result;
			this.setState({topNews: responseJson});
		});

		let sndata = { language:'E', type:"slidernews"};
		PostData('SliderNews', sndata).then((result) => {
			let responseJson = result;
			this.setState({sliderNews: responseJson});
		});

		let bulletindata = {limit: 'limit 4', language:'E', cat_id:''};
		PostData('BulletinHome', bulletindata).then((result) => {
			let responseJson = result;
			this.setState({bulletinNews: responseJson});
		});
	}

	render() {
		return (
			<div id="home">
				<Header />
				<div id="appCapsule">
					<Menu active="home" />
					<div className="tab-home">
						{/*Shorts notification :: start */}
							{/*<div className="section mt-05 mb-0" id="short-news-block">
								<div className="card">
									<div className="card-body d-flex justify-content-between align-items-end">
										<a href="shorts.php"><div>
											<p><span>Shorts:</span> India reports more than 90k cases for second consecutive day in row</p>
											<ion-icon name="chevron-forward-outline"></ion-icon>
										</div></a>
									</div>
								</div>
							</div>*/}
						{/*Shorts notification :: end */}
					
						{/*Big Main News :: start*/}
							<TopNews topNews={this.state.topNews} />
						{/*Big Main News :: end*/}
						<h3 className="lt-header">Latest News</h3>

						<SubNewsSlider sliderNews={this.state.sliderNews} />

						<div className="appfullcontainer mt-0 mb-0" >
							<div className="appContent" id="news-block">
								<LatestNews start={0} end={15} latestNews={this.state.latestNews}/>
							</div>
						</div>

						{/*this.state.bulletinNews.length>0 && <BulletinSlider bulletinNews={this.state.bulletinNews} /> */}

						{/* <div className="appfullcontainer mt-0 mb-3" >
	            			<h3>Bulletins</h3>
							<div className="bulletin-block"> 
	            				<BulletinSlider bulletinNews={this.state.bulletinNews} />

	            				<div className="clear"></div>
	            			</div>
	            			
	            			<div className="appContent" id="news-block">
	            				<LatestNews start={5} end={9} latestNews={this.state.latestNews}/>
	            			</div>
	            		</div> */}
						
						{/*<div className="appfullcontainer mt-0 mb-3" >
							<h3>live</h3>
							<div className="live-block"> 
								<div className="card">
									<a href="#">
										<div className="img-card">
											<img src="assets/img/bulletin.png" className="card-img-top" alt="image"/>
										</div>
										<div className="card-body">
											<p className="tag">Facebook live</p>
											<h4 className="mb-0">India reports more than 90k cases India reports more than 90k cases</h4>
										</div>
									</a>
								</div>
								
							</div>
						</div>*/}

					</div>
				</div>
				<Footer />
			</div>
		);
	}
}  

export default HomePage;