import React from 'react';
import ReactDOM from 'react-dom';

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const SubNewsSlider = (props) => {

    let Items = props.sliderNews.map((news, i) => {
    // console.log("props slider news: "+news.Title);
    
        let short_title = "";
        if((news.Title).length > 200){
            short_title = news.Title.substring(0, 200)+"...";
        }
        else {
            short_title = news.Title;
        }

        return (
                <div className="card" key={i}>
                    <div className="item">
                        <div className="card">
                            <a newsid={news.newsid} language={"E"} href={"/NewsDetail/"+news.newsid} >
                                <div className="img-card">
                                    <img src={news.Video_pic} className="card-img-top" alt={short_title} />
                                </div>
                                <div className="card-body">
                                    <p className="tag">{news.catname}</p>
                                    <h4 className="mb-0">{short_title}</h4>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
        );

    });

    const options = {
        items: 2,
        nav: false,
        loop: false,
        dots: false,
        stagePadding: 30
    };

    return (
        <div className="lt-carousel">
            <OwlCarousel className="owl-theme" loop margin={15} {...options}>
                    {Items}
            </OwlCarousel>
        </div>
    );
}

export default SubNewsSlider;