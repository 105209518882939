import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Menu from './Menu';
import { Redirect } from 'react-router-dom';
import { PostData } from './PostData';
import LazyLoad from "react-lazyload";

const Spinner = () => (
    <div className="post loading">
        <svg
            width="80"
            height="80"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <circle
                cx="50"
                cy="50"
                fill="none"
                stroke="#49d1e0"
                strokeWidth="10"
                r="35"
                strokeDasharray="164.93361431346415 56.97787143782138"
                transform="rotate(275.845 50 50)"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="linear"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                    dur="1s"
                    begin="0s"
                    repeatCount="indefinite"
                />
            </circle>
        </svg>
    </div>
);

class ProgramsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            cat_id: props.match.params.cid,
            Programs : [],
        }

        this.fetchProgramsList = this.fetchProgramsList.bind(this);
    }

    componentDidMount() {
        this.fetchProgramsList()
    }

    fetchProgramsList() {
        let pdata = { cid: this.state.cat_id };
        PostData('Programs', pdata).then((result) => {
            let responseJson = result;
            this.setState({ Programs: responseJson });
        });
    }
    

    render() {
        return (
            <div id="home">
                <Header />
                <div id="appCapsule">
                    <Menu active="programs" />

                    <div className="tab-program-main">
                        <div className="appfullcontainer mt-0 mb-1" >
                            <div className="programs-list-block">

                                <div className="appfullcontainer mt-0 mb-1" >
                                    {this.state.Programs.length > 0 
                                        && this.state.Programs.map((progobj, i)=>{
                                            let cat_title = "";
                                            
                                            if(i==0) {
                                                cat_title = <h3 key={i}>{progobj.cat_title}</h3>
                                            }
                                            
                                            return(   
                                                
                                                <LazyLoad
                                                    key={i}
                                                    height={100}
                                                    offset={[-100, 100]}
                                                    placeholder={<Spinner />}
                                                >
                                                {cat_title}
                                                <div className="live-block" key={i}>
                                                    <div className="card">
                                                            <a href={"/ProgramsDetail/" + progobj.cid + "/" + progobj.id} >
                                                            <div className="img-card">
                                                                <img src={progobj.VideoPic} className="card-img-top" alt="image" />
                                                            </div>
                                                            <div className="card-body">
                                                                <p className="tag">{progobj.date}</p>
                                                                <h4 className="mb-0">{progobj.title}</h4>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>

                                                </LazyLoad>
                                            );
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default ProgramsList;