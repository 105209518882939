import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Menu from './Menu';
import { Redirect } from 'react-router-dom';
import { PostData } from './PostData';
import ProgramSlider from './ProgramSlider';

class Programs extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            sliderNews: [],
            latestPrograms : [],
        }

        this.fetchProgramsPageData = this.fetchProgramsPageData.bind(this);
    }

    componentDidMount() {
        this.fetchProgramsPageData()
    }

    fetchProgramsPageData() {
        let sndata = { language: 'E', type: "slidernews" };
        PostData('ProgramCategory', sndata).then((result) => {
            let responseJson = result;
            this.setState({ sliderNews: responseJson });
        });

        let pdata = { language: 'E', type: "latestprograms" };
        PostData('Programs', pdata).then((result) => {
            let responseJson = result;
            this.setState({ latestPrograms: responseJson });
            console.log("latest programs :" + this.state.latestPrograms.length);
        });
    }

    render() {
        return (
            <div id="home">
                <Header />
                <div id="appCapsule">
                    <Menu active="programs" />

                    <div className="tab-program-main">
                        <div className="appfullcontainer mt-0 mb-1" >
                            <div className="programs-list-block">
                                <ProgramSlider sliderNews={this.state.sliderNews} />

                                <div className="appfullcontainer mt-0 mb-1" >
                                    <h3>Latest programs</h3>

                                    {this.state.latestPrograms.length > 0 
                                        && this.state.latestPrograms.map((progobj, i)=>{
                                            return(
                                                <div className="live-block" key={i}>
                                                    <div className="card">
                                                        <a href={"/ProgramsDetail/" + progobj.cid + "/" + progobj.id} >
                                                            <div className="img-card">
                                                                <img src={progobj.VideoPic} className="card-img-top" alt="image" />
                                                            </div>
                                                            <div className="card-body">
                                                                <p className="tag">{progobj.cat_title}</p>
                                                                <h4 className="mb-0">{progobj.title}</h4>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Programs;