import React from "react";
import LazyLoad from "react-lazyload";

const Spinner = () => (
    <div className="post loading">
        <svg
            width="80"
            height="80"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <circle
                cx="50"
                cy="50"
                fill="none"
                stroke="#49d1e0"
                strokeWidth="10"
                r="35"
                strokeDasharray="164.93361431346415 56.97787143782138"
                transform="rotate(275.845 50 50)"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="linear"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                    dur="1s"
                    begin="0s"
                    repeatCount="indefinite"
                />
            </circle>
        </svg>
    </div>
);

const CategoryNewsList = (props) => {
    let start = props.start;
    
    return (
        props.latestNews.map((news, i) => {
            if (i >= start) {
                return (
                    <LazyLoad
                        key={i}
                        height={100}
                        offset={[-100, 100]}
                        placeholder={<Spinner />}
                    >
                        <div className="news-bx" key={i}>
                            <a newsid={news.newsid} language={"E"} href={"/NewsDetail/" + news.newsid} >
                                <div className="img-card">
                                    <img src={news.Video_pic} className="card-img-top" alt="image" />
                                </div>
                                <div className="card-body">
                                    <p className="tag">{news.catname}</p>
                                    <h4 className="mb-0">{news.Title}</h4>
                                </div>
                                <div className="clear"></div>
                            </a>
                        </div>
                    </LazyLoad>
                );
            }
        })
    );
}

export default CategoryNewsList;