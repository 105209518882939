import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Menu from './Menu';
import { Redirect } from 'react-router-dom';
import { PostData } from './PostData';
import LazyLoad from "react-lazyload";

const Spinner = () => (
    <div className="post loading">
        <svg
            width="40"
            height="40"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <circle
                cx="50"
                cy="50"
                fill="none"
                stroke="#49d1e0"
                strokeWidth="10"
                r="35"
                strokeDasharray="164.93361431346415 56.97787143782138"
                transform="rotate(275.845 50 50)"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="linear"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                    dur="1s"
                    begin="0s"
                    repeatCount="indefinite"
                />
            </circle>
        </svg>
    </div>
);

const SrchOBJECT= (props) => {
    // console.log(props.srch.SHORTS);
    let SHORTS = "";
    if (props.srch.SHORTS.length > 0) {

        SHORTS = props.srch.SHORTS.map((post, i) => {

        return (
            <div className="news-bx" key={i}>
                <a href={process.env.PUBLIC_URL + post.url} >
                    <div className="img-card">
                        <img src={post.image} className="card-img-top" />
                    </div>

                    <div className="card-body">
                        <p className="tag">{post.date}</p>
                        <h4 className="mb-0">{post.title}</h4>
                    </div>

                    <div className="clear"></div>
                </a>
            </div>
        );
        
        });
    }


    let NEWS = "";    
    if (props.srch.NEWS.length > 0) {
        NEWS = props.srch.NEWS.map((post, i) => {

            return (
                <div className="news-bx" key={i}>
                    <a href={process.env.PUBLIC_URL + post.url} >
                        <div className="img-card">
                            <img src={post.image} className="card-img-top" />
                        </div>

                        <div className="card-body">
                            <p className="tag">{post.date}</p>
                            <h4 className="mb-0">{post.title}</h4>
                        </div>

                        <div className="clear"></div>
                    </a>
                </div>
            );

        });
    }

    let PROGRAMS = "";
    if (props.srch.PROGRAMS.length > 0) {
        PROGRAMS = props.srch.PROGRAMS.map((post, i) => {

            return (
                <div className="news-bx" key={i}>
                    <a href={process.env.PUBLIC_URL + post.url} >
                        <div className="img-card">
                            <img src={post.image} className="card-img-top" />
                        </div>

                        <div className="card-body">
                            <p className="tag">{post.date}</p>
                            <h4 className="mb-0">{post.title}</h4>
                        </div>

                        <div className="clear"></div>
                    </a>
                </div>
            );

        });
    }

    let BULLETINS = "";
    if (props.srch.BULLETINS.length > 0) {
        BULLETINS = props.srch.BULLETINS.map((post, i) => {

            return (
                <div className="news-bx" key={i}>
                    <a href={process.env.PUBLIC_URL + post.url} >
                        <div className="img-card">
                            <img src={post.image} className="card-img-top" />
                        </div>

                        <div className="card-body">
                            <p className="tag">{post.date}</p>
                            <h4 className="mb-0">{post.title}</h4>
                        </div>

                        <div className="clear"></div>
                    </a>
                </div>
            );

        });
    }


    return (
        <LazyLoad
            height={100}
            offset={[-100, 100]}
            placeholder={<Spinner />}
        >
            {SHORTS}
            {NEWS}
        </LazyLoad>
    );
}

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            SearchResult: [],
            text: props.match.params.text,
        }

        this.fetchSearch = this.fetchSearch.bind(this);
    }

    componentDidMount() {
        this.fetchSearch()
    }

    fetchSearch() {
        let data = { text: this.state.text };
        PostData('GLOBAL_SEARCH', data).then((result) => {
            let responseJson = result;
            // console.log(responseJson);
            this.setState({ SearchResult: responseJson });
        });
    }
    

    render() {
        return (
            <div id="home">
                <Header loader="Y" />
                <div id="appCapsule">
                    <Menu active="home" />
                    
                    <div className="tab-program">
                        {this.state.SearchResult.length > 0
                            && this.state.SearchResult.map((mainobj, i) => {
                                // console.log(mainobj);
                            return (
                                <div className="appfullcontainer mt-0 mb-0" key={i}>
                                    {/* <h3></h3> */}
                                    <div className="appContent" id="news-block">
                                        <SrchOBJECT srch={mainobj}/>
                                        {/* {mainobj.length > 0
                                            && mainobj.map((srchobj, i) => {
                                                console.log(srchobj);
                                                return (
                                                    
                                                );
                                            })
                                        } */}

                                    </div>
                                </div>
                                );
                            })
                        }
                    </div>

                </div>
                <Footer />
            </div>
        );
    }
}

export default Search;