import React, { Component } from 'react';

const menuItems = [
        {'title':'Home', 'url':'/', 'tag':'home'},
        { 'title': 'Shorts', 'url': '/shorts', 'tag': 'shorts'},
        { 'title': 'Programs', 'url': '/programs', 'tag': 'programs'},
        { 'title': 'Bulletins', 'url': '/Bulletin', 'tag': 'bulletins'}
];


class Menu extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const activeitem = this.props.active;

        return (
            <div id="menu">
                <div className="header-large-title" id="sub-menu">
                    <ul className="nav-menu">
                        {menuItems.length >0 &&
                            menuItems.map((menuitem, i)=>{
                                var aclass = "";
                                
                                if (activeitem == menuitem.tag){
                                    aclass="active";
                                }

                                return (
                                    <li key={i}>
                                        <a className={aclass} href={menuitem.url}>{menuitem.title}</a>
                                    </li>
                                )
                            })
                        }
                        
                        {/* <li><a className="active" href={process.env.PUBLIC_URL+"/"}>Home</a></li>
                        <li><a className="" href={process.env.PUBLIC_URL+"/shorts"}>Shorts</a></li>
                        <li><a className="" href={process.env.PUBLIC_URL+"/programs"}>Programs</a></li>
				        <li><a className="" href={process.env.PUBLIC_URL+"/bulletins"}>Bulletins</a></li> */}
                    </ul>
                </div>
            </div>
        );
    }
}

export default Menu;