import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Menu from './Menu';
import LatestNews from './LatestNews.js';
import { PostData } from './PostData';

class Live extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            latestNews: [],
        }
    }

    render() {
        return (
            <div id="category">
                <Header />
                <div id="appCapsule">
                    <Menu active="home" />
                    <div className="tab-live-main">
                        <div className="appfullcontainer mt-0 mb-1" >
                            <div className="">
                                <div itemScope itemType="http://schema.org/VideoObject">
                                    <meta itemProp="uploadDate" content="2016-05-16T23:18:21+0530" />
                                    <meta itemProp="name" content="goa 365" />
                                    <meta itemProp="contentUrl" content="http://iptv.mstudiosystem.com:1935/goa365/livestream3/playlist.m3u8" />
                                    <div >
                                        <object width="100%">
                                            <param name="movie" value="//content.jwplatform.com/players/nLfOdIlq-0wNgo3RQ.html"></param>
                                            <embed src="//content.jwplatform.com/players/nLfOdIlq-0wNgo3RQ.html" type="application/x-shockwave-flash"></embed>
                                        </object>
                                    </div>
                                </div>
                            </div>
                            <div className="live-streaming-links">
                                <a href="fb://page/124218901316478" target="_blank" className="fb-live" rel="alternate">
                                    <img src={"/assets/img/fb.png"} />
                                </a>
                                <a href="https://www.youtube.com/channel/UCDFOkbaN9IuV6tjO8Aqo1ww" target="_blank" className="yt-live">
                                    <img src={"/assets/img/yt.png"} />
                                </a>
                                <div className="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Live;