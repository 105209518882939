import React, {Component} from 'react';
import ReactDom from 'react-dom';
import {Link, Redirect} from 'react-router-dom';

import Header from './Header';
import Menu from './Menu';
import Footer from './Footer';
import { PostData } from './PostData';

class NewsDetail extends Component {
	constructor(props) {
		super(props);

	    this.state= {
			data:[],
	    	newsDetail: [],
	    	newsid: props.match.params.newsid,
			language: "E",
			catMenu: [],
		}

		// this.fetchNewsDetail = this.fetchNewsDetail.bind(this);
		this.shareClick = this.shareClick.bind(this);
	}

	componentDidMount() {
		this.fetchNewsDetail()
	}

	fetchNewsDetail() {
		let data = { "details_id":this.state.newsid, "type":"N", "language":this.state.language}
		// console.log(data);

		PostData('NewsDetails', data).then((result) => {
			let responseJson = result;
			// console.log(result);
			this.setState({newsDetail: result});
		});
	}

	 shareClick(e, share_url, share_title,share_text="") {

	    if (navigator.share) {
	        navigator.share({
	            title: share_title,
	            url: share_url,
	            text: share_text
	        }).then(() => {
	            console.log('Thanks for sharing!');
	        })
	            .catch(console.error);
	    } else {
	        console.log('device does not support sharing...');
	    }
	} 

/*	shareClick(e, share_url, share_title, share_text = "", share_image = "") {
		fetch(share_image, {
			mode: 'cors',
		})
			.then(function (response) {
				console.log(response);
				return response.blob()
			})
			.then(function (blob) {
				var file = new File([blob], share_image, { type: 'image/jpeg' });
				var filesArray = [file];
				console.log(filesArray);

				if (navigator.share && navigator.canShare({ files: filesArray })) {
					navigator.share({
						text: share_text,
						files: filesArray,
						title: share_title,
						url: share_url
					}).then(() => {
						console.log('Thanks for sharing!');
					})
						.catch(console.error);
				}
				else {
					console.log('device does not support sharing...');
				}
			});
	}*/

	render () {
			return (

				<div id="news-detail">
					<Header />
					<div id="appCapsule">
						<Menu active="home" />
						{this.state.newsDetail.length>0 && 
						this.state.newsDetail.map((newsobj, i) => {
							let title_str = newsobj.title;

							return (

									<div className="tab-news-detail" key={i}>
										<div className="news-detail-block mt-0 mb-2" >
											<h2>{newsobj.title}</h2>
											<p className="tag">{newsobj.dateofpost}</p>
											<div className="news-img-bx">
												<object width="100%">
													<param name="movie" value={newsobj.videolink}></param>
													<embed src={newsobj.videolink} type="application/x-shockwave-flash"></embed>
												</object>
											</div>
												{newsobj.shortdesc && <p dangerouslySetInnerHTML={{ __html: newsobj.shortdesc }} /> }
											<div className="news-share-box">
												{/* <button onClick={(e) => this.shareClick(e, newsobj.share_url, newsobj.title)} className="btn-box share-button" >Share</button> */}
												<button onClick={(e) => this.shareClick(e, newsobj.share_url, "", title_str, newsobj.sharePic)} className="btn-box share-button" >Share</button>
												<br/>
												<br/>
												<br/>
											</div>
										</div>
									</div>
							)
						})
					}
					</div>
					<Footer />
				</div>
			)
	}
}

export default NewsDetail;