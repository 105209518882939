import React from 'react';
import ReactDOM from 'react-dom';

const TopNews = (props) => {

	let start = props.start;
	let end = props.end;
	
    return (
        props.topNews.map((news, i) => {
			return(
				<div className="section mb-3 big-new-box" key={i}>
					<div className="card">
						<a newsid={news.newsid} language={"E"} href={"/NewsDetail/"+news.newsid} >
							<div className="img-card-box">
								<img src={news.Video_pic} className="card-img-top" alt={news.Title}/>
							</div>
							<div className="card-body">
								<p className="card-text">{news.catname}</p>
								<h3>{news.Title}</h3>
							</div>
						</a>
					</div>
				</div>
			);
        })
    );
}

export default TopNews;