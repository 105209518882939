import React from 'react';

const LatestNews = (props) => {

	let start = props.start;
	let end = props.end;
	let type = props.type;

    return (
        props.latestNews.map((news, i) => {
			// console.log(i);
			if(i>=start && i<=end)
			{
				return (
					<div className="news-bx" key={i}>
						<a newsid={news.newsid} language={"E"} href={"/NewsDetail/"+news.newsid} >
						<div className="img-card">
								<img src={news.Video_pic} className="card-img-top" alt="image" />
							</div>
							<div className="card-body">
								<p className="tag">{news.catname}</p>
								<h4 className="mb-0">{news.Title}</h4>
							</div>
							<div className="clear"></div>
						</a>
					</div>
				);
			}
		})
	);
}

export default LatestNews;