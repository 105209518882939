import React, { Component } from 'react';

class NotFound extends Component {

    render() {
        return (
            <div className="tab-404">
            	<div className="appfullcontainer mt-0 mb-1 flex-center" >
            		<div className="section">
            		  <h1 className="error">404</h1>
            		  <div className="page">Ooops!!! The page you are looking for is not found</div>
            		  <a className="back-home" href="/">Back to home</a>
            		</div>
            	</div>
            </div>
        );
    }
}

export default NotFound;