import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Menu from './Menu';
import LatestNews from './LatestNews.js';
import { PostData } from './PostData';
import CategoryNewsList from './CategoryNewsList';

class CategoryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            latestNews: [],
            cat_id: props.match.params.cid,
        }

        this.fetchCategoryList = this.fetchCategoryList.bind(this);
    }

    componentDidMount() {
        this.fetchCategoryList()
    }

    fetchCategoryList() {
        let data = { language: 'E', type: "", catid: this.state.cat_id };
        PostData('LatestNews', data).then((result) => {
            let responseJson = result;
            this.setState({ latestNews: responseJson });
        });
    }

    render () {
        return (
            <div id="category">
                <Header />
                <div id="appCapsule">
                    <Menu active="home" />
                    <div className="tab-cat">
                        <div className="appfullcontainer mt-0 mb-2" >
                            {this.state.latestNews.length >0 
                                && this.state.latestNews.map((catobj, i)=> {

                                    if(i==0){
                                        return (
                                            <div className="live-block" key={i}>
                                                <div className="card">
                                                    <a newsid={catobj.newsid} language={"E"} href={"/NewsDetail/" + catobj.newsid} >
                                                        <div className="img-card">
                                                            <img src={catobj.Video_pic} className="card-img-top" alt="image" />
                                                        </div>

                                                        <div className="card-body">
                                                            <p className="tag">{catobj.catname}</p>
                                                            <h4 className="mb-0">{catobj.Title}</h4>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    }
                                })

                            }
                        </div>

                        <div className="appfullcontainer mt-2 mb-2" >
                            <div className="appContent" id="news-block">
                                <CategoryNewsList start={1} end="" latestNews={this.state.latestNews}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default CategoryList;