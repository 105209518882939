import React, { Component } from 'react';
import { PostData } from './PostData';
import { Redirect } from "react-router-dom"; 
import $ from 'jquery';

class HomePage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			value: '',
			redirect: null,
			loader: props.loader
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleSearch = this.handleSearch.bind(this);

	}

	ToggleSearchBox() {
		var a = $("#search").hasClass("show");
		if (a) {
			$("#search").removeClass("show");
		}
		else {
			$("#search").addClass("show");
			$("#search .form-control").focus();
		}
	}

	handleChange(event) {
		this.setState({ value: event.target.value });
	}

	handleSearch = (event) => {
		event.preventDefault();
		let search_text = this.state.value;		
		this.setState({redirect: true});
	}

	render() {
		if(this.state.redirect)
		{
			return <Redirect to={process.env.PUBLIC_URL+"/Search/" + this.state.value}/>
		}
		return (
			<div id="HEADER">
				    {/*<!-- loader -->*/}
					
					{ !this.state.loader &&
						<div id="loader">
							<div className="spinner-border text-primary" role="status"></div>
						</div>
					}

				    {/*<!-- * loader -->*/}

				    {/*<!-- App Header -->*/}
				    <div className="appHeader bg-primary scrolled">
				        <div className="right">
				            <a href="#" className="headerButton" data-toggle="modal" data-target="#sidebarPanel">
				                <ion-icon name="menu-outline"></ion-icon>
				            </a>
				        </div>
		                <div className="pageTitle">
		                    <a href={process.env.PUBLIC_URL+'/'} ><img src={process.env.PUBLIC_URL +"/assets/img/GOA-365-Logo.jpg"} className="header-logo"  alt="" /></a>
		                </div>
					<a href='https://www.goa365.tv/live-stream.php'><div className="live-box">
		        			<img src={process.env.PUBLIC_URL+ "/assets/img/live.gif"} />
		        		</div></a>
				        <div className="left">
							<a href="#" className="headerButton toggle-searchbox" onClick={this.ToggleSearchBox}>
				                <ion-icon name="search-outline"></ion-icon>
				            </a>
				        </div>
				    </div>
				    {/*<!-- * App Header -->*/}

				    {/*<!-- Search Component -->*/}
				    <div id="search" className="appHeader">
					<form className="search-form" onSubmit={this.handleSearch}>
				            <div className="form-group searchbox">
								<input type="text" name="txtsearch" id="txtsearch" className="form-control" value={this.state.value} onChange={this.handleChange} placeholder="Search..." />
				                <i className="input-icon">
				                    <ion-icon name="search-outline"></ion-icon>
				                </i>
								<a href="#" className="ml-1 close toggle-searchbox" onClick={this.ToggleSearchBox}>
				                    <ion-icon name="close-circle"></ion-icon>
				                </a>
				            </div>
				        </form>
				    </div>
				    {/*<!-- * Search Component -->*/}

			</div>
		);
	}
}  

export default HomePage;