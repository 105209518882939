import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Home from './components/Home';
import NotFound from './components/NotFound';
import NewsDetail from './components/NewsDetail';
import Shorts from './components/Shorts';
import Bulletin from './components/Bulletin';
import CategoryList from './components/CategoryList';
import Live from './components/Live';
import Programs from './components/Programs';
import ProgramsList from './components/ProgramsList';
import ProgramsDetail from './components/ProgramsDetail';
import Search from './components/Search';

const Routes = () => (
	<BrowserRouter>
		<Switch>
			<Route path="/" component={Home} exact={true} />
			<Route path="/NewsDetail/:newsid" component={NewsDetail} exact={true}/>
			<Route path="/Shorts/" component={Shorts} exact={true}/>
			<Route path="/Bulletin/:type?/:bid?" component={Bulletin} />
			<Route path="/CategoryList/:cid/" component={CategoryList} />
			<Route path="/Live/" component={Live} exact={true}/>
			<Route path="/Programs/" component={Programs} exact={true} />
			<Route path="/ProgramsList/:cid/" component={ProgramsList} />
			<Route path="/ProgramsDetail/:cid/:pid" component={ProgramsDetail} />
			<Route path="/Search/:text" component={Search} />
			<Route component={NotFound} />
			{/*<Route path="/login" component={Login} exact={true} />
			<Route path="/journey/:trip" component={Journey} exact={true} />
			<Route path="/history" component={History} exact={true} />
			<Route path="/attendance" component={Attendance} exact={true} />
			<Route component={NotFound} />*/}
		</Switch>
	</BrowserRouter>
);
export default Routes;