import React, {Component} from 'react';
import { PostData } from './PostData';
import AdSense from 'react-adsense';


// export default MenuCategories = (props) => {
// 	let CatItems = props.catMenu.map((cat, i) => {
// 		console.log("cat area: "+cat.title);
// 	});
// };

class Footer extends Component {
	constructor(props) {
		super(props);

		this.state= {
			data:[],
			catMenu: [],
		}

		this.footerCatMenu = this.footerCatMenu.bind(this);
	}

	componentDidMount() {
		this.footerCatMenu()
	}

	footerCatMenu () {
		let catdata = {limit: 'limit 4', language:'E', cat_id:''};
		PostData('MenuCategory', catdata).then((result) => {
			let responseJson = result;
			this.setState({catMenu: responseJson});
		});
	}

	render() {
		
		return (
			<div id="FOOTER">
				    {/*<!-- App Bottom Menu -->*/}
				    <div className="appBottomMenu">
						<div className="ad-box">
							{/* <img src={process.env.PUBLIC_URL +"/assets/img/ad.jpg"} width="100%" alt="" /> */}
							{/*<AdSense.Google
								client='ca-pub-7715011377109742'
								slot='5266828490'
								style={{ display: 'inline-block', width: 320, height: 100 }}
								format=''
							/>*/}
						</div>
				    </div>
				    {/*<!-- * App Bottom Menu -->*/}

				    {/*<!-- App Sidebar -->*/}
				    <div className="modal fade panelbox panelbox-right" id="sidebarPanel" tabIndex="-1" role="dialog">
		                <div className="modal-dialog" role="document">
		                    <div className="modal-content">
		                        <div className="modal-body p-0">
		                            <div className="profileBox">
		                                <a href="#" className="close-sidebar-button" data-dismiss="modal">
		                                    <ion-icon name="close"></ion-icon>
		                                </a>
		        						
		                            </div>
		        					<ul className="mob-menu">
										{this.state.catMenu.length>0 && 
											this.state.catMenu.map((catobj, i) => {
												let link_str = "#";

												if (catobj.type == 'P') link_str = "/ProgramsList/" + catobj.id;
												else if (catobj.type == 'N') link_str = "/CategoryList/"+ catobj.id;

												return (
													<li key={i}>
														<a href={process.env.PUBLIC_URL + link_str}>{catobj.title}</a>
													</li>
												)
											})
										}
		        					</ul>
		                    	</div>
		                	</div>
		            	</div>
				    	{/*<!-- * App Sidebar -->*/}

				    	{/*<!-- welcome notification  -->*/}
				    	{/* <div id="notification-welcome" className="notification-box">
			    	        <div className="notification-dialog android-style">
			    	            <div className="notification-header">
			    	                <div className="in">
			    	                    <img src={process.env.PUBLIC_URL+ "/assets/img/icon/72x72.png"} alt="image" className="imaged w24" />
			    	                    <strong>GOA 365</strong>
			    	                    <span>Add to Home Screen</span>
			    	                </div>
			    	                <a href="#" className="close-button">
			    	                    <ion-icon name="close"></ion-icon>
			    	                </a>
			    	            </div>
			    	            <div className="notification-content">
			    	                <div className="in">
			    	                    <h3 className="subtitle">Welcome to GOA 365</h3>
			    	                    <div className="text">
			    	                        Goa 365 - Goa News in English &nbsp;|&nbsp; Latest News from Goa
			    	                    </div>
			    	                </div>
			    	            </div>
			    	        </div>
			    	    </div> */}
				    </div>
			</div>
		);
	}
}  

export default Footer;